import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from 'src/app/config/api-service.config';

@Injectable({
  providedIn: 'root'
})
export class PdfDownloadService {

   /**
   * apiPrefix: This variable is used to store the API prefix.
   */
  apiPrefix: string;
  headers: any;
  authHeader: any;
  AUTHORIZE_HEADERS = {
    'Content-Type': 'application/pdf',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  };


  constructor(private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.authHeader = config.authHeader;
  }


  downloadPdf(pdfObj: any) {
    return this.http.get(`${this.apiPrefix}download/${pdfObj.productId}/${pdfObj.pdfName}`, {headers: this.AUTHORIZE_HEADERS});
  }

  toCallAjaxUrl(url: any) {
    return this.http.get(url, {responseType: 'blob'});
  }

}
