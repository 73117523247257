





export enum DIGITAL_PRODUCT {
    DOWNLOADS = 'digital_download',
    SOFTWARE_TOOLS = 'business_software_tools',
    MONTHLY_SUBSCRIPTION = 'monthly_subscription'
}

export enum WEBROOT_TEXT {
    MONTHLY = 'Month',
    YEARLY = 'Year',
    MAC_VERSION = '(Mac Version)'
}


export enum MY_ACCOUNT_TITLES {
    MY_ACC = 'Order History',
    PAY_INVOICE = 'Pay My Invoice',
    MY_DOWNLOADS = 'My Downloads',
    SUBSCRIPTION = 'Subscription',
    MY_PROFILE = 'My Profile'
}






